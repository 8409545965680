import {CSSProperties, ReactNode} from "react";
import '../styles/components/Label.scss'

interface Props {
  color: string;
  text?: string | number;
  icon?: ReactNode;
  styleWrap?: CSSProperties;

  onClick?(): void;
}

export const Label = ({color, text, icon, styleWrap, onClick}: Props) => {
  const isButton = !!onClick
  return (
      <div
          className={'label-wrap'}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation()
            onClick?.()
          }}
          style={{
            backgroundColor: isButton ? '' : color + '15',
            cursor: isButton ? 'pointer' : 'default',
            border: isButton ? '1px solid ' + color : "none",
            ...styleWrap,
          }}
      >
        {icon}
        {text ? <span className={'text'} style={{color}}>{text}</span> : null}
      </div>
  )
}