import axios, {AxiosInstance} from 'axios';
import qs from 'qs';
import {formatJson, getToken, removeUserInfo} from "../utils/utils";
import {notice} from "../utils/notification";
import {eventBus} from "../utils/eventBus";

export interface ResponseModel<T = any> {
  code: number; //<0：登录失败；=0：成功；>0：出错
  msg: string;
  ret: T;
}

export interface ListRet<T = any> {
  records: T[];//首页链接
  total: number;
  size: number; //资源大
  current: number;
  pages: number;
}

//请求分页数据基础参数
export interface ListParams {
  current: number; //当前页数 不传默认为1
}

const server: AxiosInstance = axios.create({
  baseURL: '/api',
  timeout: 30000,
  method: 'post',
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});

server.interceptors.request.use(function (config) {
  if (config.headers) {
    config.headers['token'] = getToken()
  }
  if (config.data instanceof FormData) {
    //数据直接上传，内部会删除该属性，让浏览器自动设置
    // config.headers['Content-Type'] = 'multipart/form-data';
  } else {
    if (config.data?.current) {
      config.data.size = config.data.size || 15
    }
    if (config.params?.current) {
      config.params.size = config.params.size || 15
    }
    config.data = qs.stringify(config.data);
  }
  return config;
}, function (error) {
  return Promise.reject(error);
});

server.interceptors.response.use(function (response) {
  if (response.status !== 200) {
    const info = {
      url: `${response.config.baseURL}${response.config.url}`,
      status: response.status,
      statusText: response.statusText,
      error: response.data.error,
      message: response.data.message,
    }
    console.error('interceptors.response Error:', formatJson(info));
    return Promise.reject('服务器返回错误' + response.status);
  }
  const res = response.data;
  //图片上传会设置baseURL为'',特异性处理图片上传
  if (!response.config.baseURL) {
    //res为空则代表上传成功，有内容即为错误提示
    if (!res) {
      return Promise.resolve('success')
    } else {
      return Promise.reject('failed')
    }
  } else {
    const {code, msg} = res;
    if (code !== 0) {
      const {data: responseData, status, config} = response
      const {url, data, baseURL, headers,} = config
      // console.error(formatJson({status, url: `${baseURL}${url}`, data, responseData, headers,}));
      if (code === 400) {
        // notice(msg, 'error')
        eventBus.emit('user', null)
      }
      // if (code > 0) {
      //   notice(msg, 'error')
      // }
      return Promise.reject(msg || '请求出错了');
    }
    return Promise.resolve(res)
  }
}, function (error): Promise<string> {
  const {code, message, config} = error
  const {url, baseURL, data, headers} = config
  // console.error('Response Catch Error:', formatJson({code, message, url: `${baseURL}${url}`, data, headers}));
  let _message = '请求出错了';
  if (error.message.indexOf('Network Error') !== -1) {
    _message = '网络连接出错，请检查网络设置';
  }
  if (error.message.indexOf('timeout') !== -1) {
    _message = '网络请求超时，请检查网络设置';
  }
  // notice(_message, 'error')
  return Promise.reject(_message);
});

export {server}
