import {Loading} from '../components/Loading'
import {Slide, ToastContainer} from 'react-toastify'
import {Route, Routes} from 'react-router-dom'
import React from 'react'
import {Reward} from './Reward'
import {Search} from './Search'
import {Detail} from './Detail'
import {NoticeDetail} from './NoticeDetail'
import {Login} from '../components/Login'
import {Upload} from './Upload'
import {NoticeList} from './NoticeList'
import {useMedia} from '../hooks/useMedia'
import {Ban} from './Ban'
import {Top} from './Top'
import {UserList} from './UserList'

export const App = () => {
  const {isDesktop} = useMedia()

  return (
    <>
      <Loading/>
      <ToastContainer
        autoClose={1000}
        limit={isDesktop ? 5 : 1}
        position={isDesktop ? 'top-right' : 'bottom-center'}
        transition={Slide}
        draggableDirection={'y'}
        draggablePercent={60}
        hideProgressBar
        newestOnTop
      />
      <Routes>
        <Route path="/" element={<Login/>}/>
        <Route path="search" element={<Search/>}/>
        <Route path="publish" element={<Upload/>}/> {/*upload的路由path，刷新后会失效*/}
        {/*<Route path="edit" element={<Edit/>}/>*/}
        <Route path="user" element={<UserList/>}/>
        <Route path="reward" element={<Reward/>}/>
        <Route path="top" element={<Top/>}/>
        <Route path="detail/:id" element={<Detail/>}/>
        <Route path="notice" element={<NoticeList/>}/>
        <Route path="notice/:id" element={<NoticeDetail/>}/>
        <Route path="ban" element={<Ban/>}/>
        <Route path="*" element={<Login/>}/>
      </Routes>
    </>
  )
}
