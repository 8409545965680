import '../styles/components/Item.scss'
import {ItemType} from './ItemType'
import {Link, useSearchParams} from 'react-router-dom'
import {ItemModule as ItemData} from '../net/module/itemModule'
import {useMedia} from '../hooks/useMedia'
import {ClickLabel} from './ClickLabel'
import {useShareClick} from '../hooks/useShareClick'
import {Button} from './Button'
import {DataStatus, ItemStatus} from '../constants'
import {Icon} from './Icon'
import {Label} from './Label'
import {Edit} from '../pages/Edit'
import {useEffect, useState} from 'react'
import {Api} from '../net/api'
import {notice} from '../utils/notification'
import {loadData} from '../utils/utils'
import {TopKey} from '../pages/Top'
import {TopModule} from '../net/module/topModule'
import aliyun from '../assets/aliyun.png'
import baidu from '../assets/baidu.png'
import quark from '../assets/quark.png'
import lanzou from '../assets/lanzou.png'
import xunlei from '../assets/xunlei.png'
import link from '../assets/link.png'

interface Props {
  data: ItemData;
  isDetail?: boolean;

  onEdit?(data: ItemData): void;
}

export const Item = ({data, isDetail, onEdit}: Props) => {
  const [searchParams] = useSearchParams()
  const {isDesktop} = useMedia()
  const [showEdit, setShowEdit] = useState(false)
  const [showTop, setShowTop] = useState(true)
  const [showBuy, setShowBuy] = useState(data.isBuy !== 1)
  const keyword = searchParams.get('keyword') ?? ''
  const {onClick, onShare, onReject, onFailed, onPassed, onDelete} = useShareClick(data)
  const statusData = DataStatus[data.status]
  // useEffect(() => {
  //   if (showEdit) {
  //     document.documentElement.style.overflow = "hidden";
  //     document.body.style.position = "fixed";
  //     document.body.style.top = "0px";
  //     document.body.style.width = "100%";
  //   } else {
  //     document.documentElement.style.overflowY = "scroll";
  //     document.body.style.position = "static";
  //   }
  // }, [showEdit])

  useEffect(() => {
    const index = ((loadData(TopKey) || []) as TopModule[]).findIndex(item => item.resources.id === data.id)
    if (index !== -1) setShowTop(false)
  }, [])

  const onClose = () => setShowEdit(false)
  const disableAuto = async () => {
    try {
      const {ret} = await Api.edit({...data, coverLink: '无'})
      notice('已禁止自动添加封面信息', 'success')
    } catch (e) {
      notice(e + '', 'error')
    }
  }

  const setTop = async () => {
    try {
      await Api.addTop({resourcesId: data.id})
      setShowTop(false)
    } catch (e) {
      notice(e + '', 'error')
    }
  }

  const setBuy = async () => {
    if (window.confirm('确定将『' + data.name + (showBuy ? '』设为首发资源?' : '』取消首发?'))) {
      try {
        await Api.setPayResources({resourcesId: data.id, money: 2.99, isBuy: showBuy ? 1 : 2})
        setShowBuy(!showBuy)
      } catch (e) {
        notice(e + '', 'error')
      }
    }
  }

  return (
    <>
      <div className={'item'}>
        <div className={'item-header'} style={{color: statusData.color}}>
          {/*[{statusData.text}]*/}
          <span style={{color: '#444', fontSize: 13}}>ID: {data.id}</span>
          <div style={{flex: 1}}/>
          <span
            // src="https://img3.doubanio.com/favicon.ico"
            // alt=""
            style={{marginRight: 15, cursor: 'pointer'}}
            onClick={() => {
              window.open(`https://search.douban.com/movie/subject_search?search_text=${data.name}&cat=1002`)
            }}
          >豆瓣</span>
          {showTop ? <span className={'edit'} style={{color: '#098c60'}} onClick={setTop}>置顶</span> : null}
          <span className={'edit'} style={{color: '#eca408'}} onClick={setBuy}>{showBuy ? '首发' : '取消首发'}</span>
          <span className={'edit'} style={{color: '#b58900'}} onClick={disableAuto}>禁止封面</span>
          <span className={'delete'} onClick={onDelete}>删除</span>
          <span className={'edit'} onClick={() => setShowEdit(true)}>编辑</span>
        </div>
        {
          isDetail ?
            <p className={'detail-title'}>{data.name}</p> :
            <Link
              className={'title'}
              to={'/detail/' + data.id}
              state={data}
              dangerouslySetInnerHTML={{
                __html: data.name.replaceAll(keyword, `<span class="flag">${keyword}</span>`),
              }}
            />
        }
        <div className="content-info">
          <ItemType data={data}/>
          <ClickLabel text={data.clickNumber}/>
          <span style={{flex: 1}}/>
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {
              data.url.split(';').map((item, index) => {
                const {icon, color, text} = getPanName(item)
                return (
                  <Label
                    key={index}
                    onClick={() => window.open(item, 'blank')}
                    color={color}
                    icon={
                      <img
                        src={icon} alt={'pan-logo'} style={{
                        width: isDesktop ? 75 : 65,
                        height: isDesktop ? 18 : 14,
                        objectFit: 'contain',
                      }}
                      />
                    }
                    styleWrap={{marginRight: 5}}
                  />
                )
              })
            }
          </div>
          <Label
            onClick={onShare}
            color={'#6A90FF'}
            icon={<Icon name={'share'} color={'#728ff7'} size={isDesktop ? 18 : 15}/>}
            styleWrap={{paddingLeft: 6, paddingRight: 6}}
            text={'分享'}
          />
          {
            data.status !== ItemStatus.passed ?
              <Button
                onClick={onPassed}
                color={'#57b580'}
                text={'通过'}
              /> : null
          }
          {
            data.status === ItemStatus.waiting ?
              <Button
                onClick={onReject}
                color={'#da655e'}
                text={'拒绝'}
              /> : null
          }
          {/*{*/}
          {/*  data.status !== ItemStatus.failed ?*/}
          {/*    <Button*/}
          {/*      onClick={onFailed}*/}
          {/*      color={'#acacac'}*/}
          {/*      text={'失效'}*/}
          {/*    /> : null*/}
          {/*}*/}
        </div>
        <div className={'content'}>
          <div className="content-left">
            {
              (data.description || data.coverLink) && !isDetail ?
                <Link to={'/detail/' + data.id} state={data}>
                  <pre className={'desc'}>{data.description}</pre>
                </Link> :
                null
            }
            <div className={'info'}>
              <a
                className={'username'} href={'https://dapanso.com/user/' + data.userId}
                target={'_blank'}
              >
                {data.user?.name}
              </a>
              <span className={'time'}>{data.createdAt}</span>
            </div>
          </div>
          {
            data.coverLink && !isDetail ?
              <img className={'cover'} src={data.coverLink} alt={data.name}/> : null
          }
        </div>
      </div>
      {
        showEdit ?
          <div className={'edit-wrap'}>
            <Edit data={data} onClose={onClose} onSuccess={onEdit}/>
          </div> :
          null
      }
    </>
  )
}

export function getPanName(str: string = ''): { icon: string, color: string, text: string } {
  let icon: string = link, color: string = '#6A90FF', text: string = '链接直达'
  if (str.toLowerCase().indexOf('ali') > -1) {
    icon = aliyun
    color = '#637dff'
    text = '阿里云盘'
  }
  if (str.toLowerCase().indexOf('baidu') > -1) {
    icon = baidu
    color = '#06a7ff'
    text = '百度网盘'
  }
  if (str.toLowerCase().indexOf('quark') > -1) {
    icon = quark
    color = '#222222'
    text = '夸克网盘'
  }
  if (str.toLowerCase().indexOf('lanzou') > -1) {
    icon = lanzou
    color = '#e7745c'
    text = '蓝奏云'
  }
  if (str.toLowerCase().indexOf('xunlei') > -1) {
    icon = xunlei
    color = '#457EED'
    text = '迅雷云盘'
  }
  return {icon, color, text}
}
