import React, {ChangeEventHandler, forwardRef, InputHTMLAttributes} from 'react'

interface Props extends Omit<InputHTMLAttributes<any>, 'onChange'> {
  onChange(text: string): void
}

export const Input = forwardRef<HTMLInputElement, Props>((props, ref) => {
  const onChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    props.onChange?.(e.target.value.trim())
    e.stopPropagation()
  }
  const onKeyUp = (e: any) => {
    if (e.keyCode === 13) {
      props.onSubmit?.(e)
    }
  }
  return <input ref={ref} {...props} onChange={onChange} onKeyUp={onKeyUp}/>
})
