import {Notice} from "../net/module/notice"
import {UserInfo} from "../net/module/userInfo";
import dayjs from "dayjs";
import {default as _isToday} from 'dayjs/plugin/isToday'

dayjs.extend(_isToday)

export function saveData(name: string, value: any) {
  if (value) {
    localStorage.setItem(name, JSON.stringify(value))
  }
}

export function removeData(name: string) {
  localStorage.removeItem(name)
}

export function loadData(name: string): { [key: string]: any } | null {
  const data = localStorage.getItem(name)
  if (data) {
    return JSON.parse(data)
  }
  return null
}

let _noticeList: Notice[] = []
const NOTICE_KEY = 'NOTICE_KEY'

export function setNoticeData(noticeList: Notice[]) {
  _noticeList = noticeList
  saveData(NOTICE_KEY, {[NOTICE_KEY]: noticeList})
}

export function getNoticeData(): Notice[] {
  if (_noticeList) return _noticeList
  const data = loadData(NOTICE_KEY)
  if (data) {
    _noticeList = data[NOTICE_KEY]
  } else {
    _noticeList = []
  }
  return _noticeList;
}

let _token: string
const TOKEN_KEY = 'tokenKey'

export function setToken(token: string) {
  _token = token
  saveData(TOKEN_KEY, {[TOKEN_KEY]: token})
}

export function getToken(): string {
  if (_token) return _token
  const data = loadData(TOKEN_KEY)
  if (data) {
    _token = data[TOKEN_KEY]
  } else {
    _token = ''
  }
  return _token;
}

export function removeToken() {
  removeData(TOKEN_KEY)
}

let _userInfo: UserInfo | null = null
const USER_INFO = 'userInfoKey'

export function setUserInfo(userInfo: UserInfo | null) {
  _userInfo = userInfo
  if (userInfo) {
    saveData(USER_INFO, {[USER_INFO]: userInfo})
  } else {
    removeUserInfo()
  }
}

export function getUserInfo(): UserInfo | null {
  if (_userInfo) return _userInfo
  const data = loadData(USER_INFO)
  if (data) {
    _userInfo = data[USER_INFO]
  } else {
    _userInfo = null
  }
  return _userInfo;
}

export function removeUserInfo() {
  _userInfo = null
  removeData(USER_INFO)
}

// 字符串的下划线格式转驼峰格式，eg：hello_world => helloWorld
function underline2Hump(str: string) {
  return str.replace(/_(\w)/g, ($0, $1) => $1.toUpperCase());
}

// JSON对象的key值转换为驼峰式
export function snack2camel(obj: any) {
  if (obj instanceof Array) {
    obj.forEach((v) => snack2camel(v));
  } else if (obj instanceof Object) {
    Object.keys(obj).forEach((key) => {
      const newKey = underline2Hump(key);
      if (newKey !== key) {
        obj[newKey] = obj[key];
        delete obj[key];
      }
      snack2camel(obj[newKey]);
    });
    return obj;
  }
}

export function camel(data: any): any {
  if (typeof data != 'object' || !data) return data
  if (Array.isArray(data)) {
    return data.map(item => camel(item))
  }

  const newData: { [key: string]: any } = {}
  for (let key in data) {
    let newKey = key.replace(/_([a-z])/g, (p, m) => m.toUpperCase())
    newData[newKey] = camel(data[key])
  }
  return newData
}

export function underline(data: any): any {
  if (typeof data != 'object' || !data) return data
  if (Array.isArray(data)) {
    return data.map(item => underline(item))
  }

  const newData: { [key: string]: any } = {}
  for (let key in data) {
    let newKey = key.replace(/([A-Z])/g, (p, m) => `_${m.toLowerCase()}`)
    newData[newKey] = underline(data[key])
  }
  return newData
}

export function invisiblePhone(phone: string | null | undefined) {
  if (!phone) return "暂无手机号";
  return (phone.substring(0, 3) + "****" + phone.substring(7));
}

export function pad(str: string | number) {
  return +str >= 10 ? str : "0" + str;
}

export function formatNum(data: string | number, zeroText?: string) {
  if (!data && typeof data !== "number") return;
  let text: string | number;
  const _number = parseInt(data + "");
  if (_number <= 0) {
    return zeroText;
  } else {
    // if (_number > 99999999) {
    //   text = (_number / 100000000).toFixed(1) + '亿';
    // } else if (_number > 999999) {
    //   text = (_number / 1000000).toFixed(1) + '百万';
    // } else
    if (_number > 9999) {
      text = (_number / 10000).toFixed(0) + "w";
    } else {
      text = _number;
    }
  }
  return text;
}

//
// export function formatDate(timestamp: ConfigType) {
//   // const dateObj = new Date(timestamp);
//   // const year = dateObj.getFullYear();
//   // const month = pad(dateObj.getMonth() + 1);
//   // const date = pad(dateObj.getDate());
//   // return year + "-" + month + "-" + date;
//   return dayjs(timestamp).format("YYYY-MM-DD");
// }

// export function formatTime(timestamp: string) {
//   // console.log(timestamp);
//   const his = dayjs(timestamp)
//   const now = dayjs();
//   const diff = (now.diff(his, "d"));
//   if (his.isToday()) {
//     return his.format("HH:mm"); //hh:mm 代表的是12小时制
//   } else if (diff < 8) {
//     return his.fromNow();
//   } else if (now.year() !== his.year()) {
//     return his.format("YYYY.MM.DD");
//   } else {
//     // return his.startOf('h').fromNow();
//     // return his.format("M.D h:m") //不补0
//     return his.format("MM.DD");
//   }
// }


export function formatNickname(value?: string) {
  if (!value) return "";
  return value.slice(0, 6) + (value.length > 6 ? "..." : "");
}

export function formatJson(value: any) {
  return JSON.stringify(value, null, 2);
}

export function isUrl(url: string) {
  return /^(https?:\/\/)([0-9a-z.]+)(:[0-9]+)?([/0-9a-z.]+)?(\?[0-9a-z&=]+)?(#[0-9-a-z]+)?/i.test(url)
}

export function formatDate(data: string, format: string = 'YYYY-MM-DD') {
  return dayjs(data).format(format)
}

export function isToday(str: string) {
  return dayjs(str).isToday()
}