import {Button} from "./Button";
import React, {ChangeEventHandler, useState} from "react";
import '../styles/components/Login.scss'
import {Api} from "../net/api";
import {notice} from "../utils/notification";
import {eventBus, hideLoading, showLoading} from "../utils/eventBus";
// @ts-ignore
import sha256 from 'crypto-js/sha256';
// @ts-ignore
import md5 from 'crypto-js/md5';
import {Input} from "./Input";
import {useNavigate} from "react-router-dom";
import {setToken, setUserInfo} from "../utils/utils";
import {UserInfo} from "../net/module/userInfo";

function getPwd(pwd: string) {
  return md5(Math.random().toString()).toString().substring(0, 3) + sha256(sha256(pwd)).toString() + md5(Math.random().toString()).toString().substring(0, 3)
}

interface Props {
}

export const Login = ({}: Props) => {
  const [username, setUsername] = useState('')
  const [pwd, setPwd] = useState('')
  const navigate = useNavigate()
  const login = async () => {
    try {
      showLoading()
      const {ret} = await Api.login({username, password: pwd})
      setToken(ret.token)
      hideLoading()
      //伪造个人信息，不要通过eventbus，因为这时header还未创建
      setUserInfo({user: {name: '管理员'}} as UserInfo)
      navigate('/search')
    } catch (e) {
      hideLoading()
      notice(e + '', 'error')
    }
  }
  return (
      <div className="login">
        <div className="content" onClick={(e) => e.stopPropagation()}>
          <div className="login-type">
            <div className={'check-login active'}>登录</div>
          </div>
          <Input type="text" placeholder={'请输入账号（必填）'} value={username} onChange={setUsername}/>
          <Input type="password" placeholder={'请输入密码（必填）'} value={pwd} onChange={setPwd}/>
          <Button text={'登录'} onClick={login}/>
        </div>
      </div>
  )
}
