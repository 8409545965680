import '../styles/components/Announcement.scss'
import {Notice} from "../net/module/notice";
import {Link, useNavigate} from "react-router-dom";
import {Button} from "./Button";
import {NoticeDataStatus, NoticeStatus} from "../constants";
import {Api} from "../net/api";
import {notice} from "../utils/notification";
import {MouseEventHandler} from "react";
import {eventBus} from "../utils/eventBus";

interface Props {
  data: Notice;
}

export const Announcement = ({data}: Props) => {
  const navigate = useNavigate()
  const onClick = async (status: NoticeStatus) => {
    try {
      await Api.checkNotice({id: data.id, status})
      notice('操作成功', 'success')
      eventBus.emit('notice', {...data, status})
    } catch (e) {
      notice(e + '', 'error')
    }
  }
  const onPassed: MouseEventHandler = (e) => {
    e.preventDefault()
    onClick(NoticeStatus.passed)
  }
  const onReject: MouseEventHandler = (e) => {
    e.preventDefault()
    onClick(NoticeStatus.reject)
  }

  const onEdit: MouseEventHandler = (e) => {
    e.preventDefault()
    navigate('/upload', {state: data})
  }
  return (
      <Link className={'announcement'} to={'/notice/' + data.id}>
        {/*<img src={noticePng} alt=""/>*/}
        <div className="a-content">
          <div style={{color: NoticeDataStatus[data.status].color, fontWeight: 500, fontSize: 15, marginBottom: 5}}>
            [{NoticeDataStatus[data.status].text}]
          </div>
          <p className={'a-title'} dangerouslySetInnerHTML={{__html: data.content}}/>
          {/*<p className={'a-desc'}>{data.content}</p>*/}
        </div>
        <div className={'button-wrap'}>
          <Button
              onClick={onEdit}
              color={'#579cb5'}
              text={'编辑'}
          />
          {
            data.status !== NoticeStatus.passed ?
                <Button
                    onClick={onPassed}
                    color={'#57b580'}
                    text={'通过'}
                /> : null
          }
          {
            data.status !== NoticeStatus.reject ?
                <Button
                    onClick={onReject}
                    color={'#da655e'}
                    text={'禁用'}
                /> : null
          }
        </div>
      </Link>
  )
}