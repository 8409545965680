class EventBus {
  private readonly eventList: { [key: string]: Function[] }

  constructor() {
    this.eventList = {}
  }

  off(event: string) {
    let fnList = this.eventList[event]
    if (fnList && fnList.length) {
      this.eventList[event] = []
    }
  }

  on(event: string, fn: Function) {
    let fnList = this.eventList[event]
    fnList ? fnList.push(fn) : this.eventList[event] = [fn]
  }

  emit(event: string, ...args: any) {
    if (this.eventList[event]) {
      this.eventList[event].forEach(fn => fn(...args))
    }
  }
}

export const eventBus = new EventBus()

export const showLoading = () => {
  eventBus.emit('showLoading')
}
export const hideLoading = () => {
  eventBus.emit('hideLoading')
}