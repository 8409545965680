import '../styles/Detail.scss'
import {Header} from '../components/Header'
import React, {useEffect, useState} from 'react'
import {Link, useLocation, useNavigate, useParams} from 'react-router-dom'
import {ItemModule} from '../net/module/itemModule'
import {notice} from '../utils/notification'
import {useShareClick} from '../hooks/useShareClick'
import {LoadingImg} from '../components/Loading'
import {useMedia} from '../hooks/useMedia'
import {Edit} from './Edit'
import {Api} from '../net/api'
import {Item} from '../components/Item'

export const Detail = () => {
  const {id} = useParams()
  const {isDesktop} = useMedia()
  const location = useLocation()
  const navigate = useNavigate()
  const [showEdit, setShowEdit] = useState(false)
  const [data, setData] = useState<ItemModule | undefined>(location.state as ItemModule)
  useEffect(() => {
    //路由跳转后不再顶部
    window.scrollTo(0, 0)
    if (id) {
      // @ts-ignore
      // if (!location.state || location.state?.id.toString() !== id) {
      getData(id)
      // }
    } else {
      notice('资源不存在', 'error')
      navigate('/')
    }
  }, [id, location.state])
  // useEffect(() => {
  //   if (showEdit) {
  //     document.documentElement.style.overflow = "hidden";
  //     document.body.style.position = "fixed";
  //     document.body.style.top = "0px";
  //     document.body.style.width = "100%";
  //   } else {
  //     document.documentElement.style.overflowY = "scroll";
  //     document.body.style.position = "static";
  //   }
  // }, [showEdit])
  const getData = async (id: string) => {
    try {
      // @ts-ignore
      (!location.state || location.state?.id.toString() !== id) && setData(undefined) //当前页点击置顶链接，切换显示loading
      const {ret} = await Api.getResourcesInfo(id)
      setData(ret)
    } catch (e) {
      notice(e + '', 'error')
    }
  }
  const onClose = () => setShowEdit(false)
  return (
    <>
      <Header/>
      <div className={'detail'}>
        <div className={'detail-left'}>
          {
            !data ?
              <div className={'page-loading-wrap'}>
                <LoadingImg/>
              </div> :
              <>
                <Item isDetail data={data} onEdit={setData}/>
                {/*<div className={'detail-header'}>*/}
                {/*  <div style={{flex: 1}}/>*/}
                {/*  <img*/}
                {/*      src="https://img3.doubanio.com/favicon.ico"*/}
                {/*      alt=""*/}
                {/*      style={{width: 20, marginRight: 15, cursor: "pointer",}}*/}
                {/*      onClick={() => {*/}
                {/*        window.open(`https://search.douban.com/movie/subject_search?search_text=${data.name}&cat=1002`)*/}
                {/*      }}*/}
                {/*  />*/}
                {/*  <span className={'delete'} onClick={onDelete}>删除</span>*/}
                {/*  <span className='edit' onClick={() => setShowEdit(true)}>编辑</span>*/}
                {/*</div>*/}
                {/*<div className={'user-wrap'}>*/}
                {/*  <p className={'username'}>{data.user?.name}</p>*/}
                {/*  <span className={'time'}>{data.createdAt}</span>*/}
                {/*</div>*/}
                {/*<p className={'title'}>{data.name}</p>*/}
                {/*<div className="labels">*/}
                {/*  <ItemType data={data}/>*/}
                {/*  <ClickLabel text={data.clickNumber}/>*/}
                {/*  <span style={{flex: 1}}/>*/}
                {/*  <Label*/}
                {/*      onClick={onShare}*/}
                {/*      color={'#6A90FF'}*/}
                {/*      text={'分享'}*/}
                {/*      icon={<Icon name={'share'} size={isDesktop ? 22 : 15}/>}*/}
                {/*      styleWrap={{paddingLeft: 6, paddingRight: 6}}*/}
                {/*  />*/}
                {/*  <Label*/}
                {/*      onClick={onClick}*/}
                {/*      color={'#6A90FF'}*/}
                {/*      text={'云盘链接'}*/}
                {/*      icon={<Icon name={'link'} size={isDesktop ? 22 : 15}/>}*/}
                {/*      styleWrap={{marginLeft: 10}}*/}
                {/*  />*/}
                {/*</div>*/}
                <div style={{padding: isDesktop ? 20 : 15, marginTop: -50}}>
                  {
                    data.coverLink ?
                      <img
                        className={'cover'}
                        src={data.coverLink.replace('s_ratio_poster', 'l_ratio_poster').replace('.webp', '.jpg')}
                        alt={data.name}
                      /> :
                      null
                  }
                  {data.description ? <pre className={'desc'}>{data.description}</pre> : null}
                </div>
              </>
          }
        </div>
        <div className="detail-right"/>
      </div>
      {
        showEdit && data ?
          <div className={'edit-wrap'}>
            <Edit data={data} onClose={onClose} onSuccess={setData}/>
          </div> :
          null
      }
    </>
  )
}
