import {Icon} from "./Icon";
import ReactPaginate from "react-paginate";
import React from "react";
import {useMedia} from "../hooks/useMedia";

interface Props {
  pages: number;
  current: number;

  onPageChange(page: number): void;
}

export const Pagination = ({current, pages, onPageChange}: Props) => {
  const {isDesktop} = useMedia()
  const _onPageChange = ({selected}: { selected: number }) => {
    onPageChange(selected + 1)
  }
  if (pages < 2) return null
  return (
      <ReactPaginate
          pageCount={pages}
          forcePage={current - 1}
          onPageChange={_onPageChange}
          previousLabel={<Icon name={'left'} size={30}/>}
          nextLabel={<Icon name={'right'} size={30}/>}
          breakLabel="···"
          pageRangeDisplayed={isDesktop ? 3 : 2}
          marginPagesDisplayed={isDesktop ? 3 : 1}
          containerClassName="pagination"
          pageClassName="page-item"
          breakClassName="page-item"
          pageLinkClassName="page-link"
          breakLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          activeClassName="active"
          disabledClassName="disable-link"
      />
  )
}