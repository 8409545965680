import Icons from '../assets/icons.svg'
import {CSSProperties, MouseEventHandler} from "react";

interface Props {
  name: string;
  color?: string;
  size?: number | string;
  style?: CSSProperties;
  className?: string | undefined;

  onClick?: MouseEventHandler<SVGSVGElement>;
}

export const Icon = ({name, className = '', color, size = 20, style, onClick}: Props) => (
    <svg className={`icon icon-${name} ${className}`} fill={color} style={{width: size, height: size, ...style}} onClick={onClick}>
      <use xlinkHref={`${Icons}#icon-${name}`}/>
    </svg>
);