import React, {useEffect, useState} from 'react'
import {Api} from '../net/api'
import {notice} from '../utils/notification'
import {Header} from '../components/Header'
import {LoadingImg} from '../components/Loading'
import {Pagination} from '../components/Pagination'
import {BanModule, BanStatus, BanType} from '../net/module/banModule'
import {Button} from '../components/Button'
import dayjs from 'dayjs'
import {isToday} from '../utils/utils'

export const Ban = () => {
  const [data, setData] = useState<BanModule[]>([])
  const [current, setCurrent] = useState(1)
  const [pages, setPages] = useState(0)
  const [loading, setLoading] = useState(false)
  let isUnmount = false

  useEffect(() => {
    window.scrollTo(0, 0)
    getData()
    return () => {
      isUnmount = true
    }
  }, [current])

  const getData = async () => {
    try {
      setLoading(true)
      const {ret} = await Api.banList({current, size: 100})
      if (!isUnmount) {
        setData(ret.records)
        setPages(ret.pages)
        setLoading(false)
      }
    } catch (e) {
      if (!isUnmount) {
        notice(e + '', 'error')
        setLoading(false)
      }
    }
  }
  const unlock = async (item: BanModule, index: number) => {
    try {
      if (window.confirm(`确定解封 IP: ${item.banIp} ？`)) {
        await Api.banUnblock(item.id)
        if (!isUnmount) {
          const data: BanModule = {...item, status: BanStatus.unBan}
          setData(prevState => prevState.map(i => i.id === item.id ? data : i))
          notice('解封成功', 'success')
        }
      }
    } catch (e) {
      if (!isUnmount) {
        notice(e + '', 'error')
      }
    }
  }
  return (
    <>
      <Header onSearch={getData}/>
      <div className="notice">
        <main className={'main'}>
          <div className={'main-left'}>
            {
              loading || data.length === 0 ?
                <div className={'page-loading-wrap'}>
                  {
                    !loading ?
                      <span className={'no-tips'}>{'暂无数据'}</span> :
                      <LoadingImg/>
                  }
                </div> :
                <div className={'list'}>
                  {
                    data.map((item, index) => <BanItem data={item} key={item.id} unlock={() => unlock(item, index)}/>)
                  }
                </div>
            }
            <Pagination
              pages={pages}
              current={current}
              onPageChange={setCurrent}
            />
          </div>
          <div className="main-right"/>
        </main>
      </div>
    </>
  )
}

const BanItem = ({data, unlock}: { data: BanModule, unlock(): void }) => {
  return (
    <div style={{display: 'flex', alignItems: 'center', background: isToday(data.updatedAt) ? '#f4fff5' : '#fff', padding: '15px 20px', marginBottom: 1}}>
      <div style={{color: '#090', width: 55, textAlign: 'right', fontSize: 15, fontWeight: 'bold'}}>{BanType[data.type]}</div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <p style={{fontSize: 17, color: '#333', width: 220, textAlign: 'center'}}>{data.banIp}</p>
        <span style={{fontSize: 15, color: '#888'}}>
            {dayjs(data.createdAt).format('MM/DD HH:MM')}{/* → {data.unsealingTime.slice(5, -3)}*/}
          </span>
      </div>
      <div style={{flex: 1}}/>
      <div style={{display: 'flex', alignItems: 'center'}}>
          <span style={{color: data.status === BanStatus.ban ? '#de3d61' : '#07bc0c', marginRight: 20, fontSize: 14}}>
            {data.status === BanStatus.ban ? '已封禁' : '已解封'}
          </span>
        <Button style={{borderRadius: 2, padding: '5px 15px', fontSize: 13}} text={'解封'} onClick={unlock}/>
      </div>
    </div>
  )
}
