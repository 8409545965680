import '../styles/Upload.scss'
import React, {FormEventHandler, useState} from 'react'
import {Button} from '../components/Button'
import {Header} from '../components/Header'
import {Icon} from '../components/Icon'
import {Input} from '../components/Input'
import {Api} from '../net/api'
import {User} from '../net/module/userInfo'
import {hideLoading, showLoading} from '../utils/eventBus'
import {notice} from '../utils/notification'

export const Reward = () => {
  const [email, setEmail] = useState('')
  const [data, setData] = useState<User[]>([])
  const [user, setUser] = useState<User>()
  const [showDialog, setShowDialog] = useState(false)
  const [reward, setReward] = useState<{ money: number; month: number }>()
  const getData = async () => {
    try {
      showLoading()
      const {ret} = await Api.userList({current: 1, size: 50, email})
      setData(ret.records)
      hideLoading()
    } catch (e) {
      notice(e + '', 'error')
      hideLoading()
    }
  }
  const onSubmit: FormEventHandler = async (e) => {
    e.preventDefault()
    await getData()
  }
  const openDialog = (user: User) => {
    setUser(user)
    setShowDialog(true)
  }
  const onCancel = () => {
    setShowDialog(false)
    setUser(undefined)
    setReward(undefined)
  }
  const onConfirm = async () => {
    if (!user) notice('未选择用户', 'error')
    if (!reward) notice('未选择赞赏期限', 'error')
    try {
      showLoading()
      await Api.editReward({id: user!.id, money: reward!.money, month: reward!.month})
      hideLoading()
      onCancel()
      await getData()
    } catch (e) {
      notice(e + '', 'error')
      hideLoading()
    }
  }
  return (
    <>
      <Header/>
      <div className={'upload'}>
        <div className={'up-left'}>
          <form onSubmit={onSubmit} style={{display: 'flex'}}>
            <Input
              placeholder={'邮箱搜索'}
              value={email}
              onChange={setEmail}
              style={{minWidth: 160, width: '100%', fontSize: 14, borderRadius: 0, border: '1px solid #ccc', height: 35, padding: '0 10px'}}
            />
            <Button onClick={getData} text={'搜索'} style={{minWidth: 70, padding: '0 10px', borderRadius: 0, fontSize: 14, marginLeft: -1, height: 37}}/>
          </form>
          <div style={{height: '55vh', overflowY: 'auto'}}>
            {
              data.map(user => (
                <div key={user.id} style={{display: 'flex', borderBottom: '.5px solid #ddd', padding: '10px 0', marginRight: 15}} onClick={() => openDialog(user)}>
                  <div style={{width: 60, fontSize: 13, color: '#666'}}>{user.id}</div>
                  <span style={{flex: 1}}>{user.email}</span>
                  <span style={{fontSize: 13, color: '#666'}}>{user.name.slice(0, 10)}</span>
                </div>
              ))
            }
          </div>
        </div>
        <div className={'up-right main'}>
        </div>
      </div>
      {
        showDialog ? <div className={'dialog'}>
            <div style={{backgroundColor: '#fff', padding: 15, borderRadius: 2}}>
              <div style={{fontSize: 13, color: '#666'}}>{user?.id} {user?.name?.slice(0, 10)} {user?.endTime}</div>
              <div style={{marginTop: 5}}>{user?.email}</div>
              <div style={{margin: '15px 0', display: 'flex', flexDirection: 'row', gap: 15}}>
                {
                  [{money: 15, month: 1}, {money: 60, month: 6}, {money: 100, month: 12}].map(item => (
                    <div
                      key={item.money}
                      className={'m-item ' + (reward?.money === item.money ? 'active' : '')}
                      onClick={() => setReward(item)}
                    >{item.money}元:{item.month}月</div>
                  ))
                }
              </div>
              <div style={{display: 'flex', justifyContent: 'flex-end', gap: 15, fontSize: 15, marginTop: 20}}>
                <span style={{color: '#666', cursor: 'pointer'}} onClick={onCancel}>取消</span>
                <span style={{color: '#6A90FF', cursor: 'pointer'}} onClick={onConfirm}>确定</span>
              </div>
            </div>
          </div> :
          null
      }
    </>
  )
}
