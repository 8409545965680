import {ListRet, ResponseModel, server} from './server'
import {ItemModule} from './module/itemModule'
import {ItemStatus, NoticeStatus, Type} from '../constants'
import {Notice} from './module/notice'
import {Keyword} from './module/keyword'
import {BanModule} from './module/banModule'
import {TopModule} from './module/topModule'
import {User} from './module/userInfo'
import {ShareModule} from './module/shareModule'
import {PurchaseModule} from './module/purchaseModule'
import {ZanModule} from './module/zanModule'

//type:1 查看真实用户数据
function getResourceList(data: { name?: string, current?: number, size?: number, type?: number, status?: ItemStatus | null, isBuy?: 1 | 2 }) {
  return server.request<any, ResponseModel<ListRet<ItemModule>>>({
    url: '/admin/resources/list',
    method: 'get',
    params: data,
  })
}

function getResourcesInfo(id: number | string) {
  return server.request<any, ResponseModel<ItemModule>>({
    url: '/admin/resources/get',
    method: 'get',
    params: {id},
  })
}

function getNoticeList(current?: number) {
  return server.request<any, ResponseModel<ListRet<Notice>>>({
    url: '/admin/notice/list',
    method: 'get',
    params: {current},
  })
}

function addNotice(data: { content: string }) {
  return server.request<any, ResponseModel<{ id: number }>>({
    url: '/admin/notice/add',
    data,
  })
}

function editNotice(data: { content: string, id: number }) {
  return server.request<any, ResponseModel<null>>({
    url: '/admin/notice/edit',
    data,
  })
}

function checkNotice(data: { id: number, status: NoticeStatus }) {
  return server.request<any, ResponseModel<null>>({
    url: '/admin/notice/check',
    data,
  })
}

function login(data: { username: string, password: string }) {
  return server.request<any, ResponseModel<{ token: string }>>({
    url: '/admin/login',
    data,
  })
}

function logout() {
  return server.request<any, ResponseModel<null>>({
    url: '/sign-out',
  })
}

function check(data: { id: number, status: ItemStatus, }) {
  return server.request<any, ResponseModel<null>>({
    url: '/admin/resources/check',
    method: 'get',
    params: data,
  })
}

function getVisits() {
  return server.request<any, ResponseModel<{ day: number }>>({
    url: '/admin/device/number-of-visits',
    method: 'get',
  })
}

function edit(data: { id: number, name?: string, url?: string, type?: Type, description?: string, coverLink?: string }) {
  return server.request<any, ResponseModel<null>>({
    url: '/admin/resources/edit',
    data,
  })
}

function deleteItem(id: number) {
  return server.request<any, ResponseModel<null>>({
    url: '/admin/resources/del',
    method: 'delete',
    data: {id},
  })
}

function searchRecordsList(data: { current: number, size?: number }) {
  return server.request<any, ResponseModel<ListRet<Keyword>>>({
    url: '/admin/search-records/list',
    method: 'get',
    params: data,
  })
}

function banList(data: { current: number, size?: number }) {
  return server.request<any, ResponseModel<ListRet<BanModule>>>({
    url: '/admin/ban/list',
    data,
  })
}

function banUnblock(id: number) {
  return server.request<any, ResponseModel<null>>({
    url: '/admin/ban/unblock',
    data: {id},
  })
}

function allowAll() {
  return server.request({
    url: '/admin/resources/one-click-audit',
  })
}

//获取置顶列表
function getTopList(data: { current: number, size?: number }) {
  return server.request<any, ResponseModel<ListRet<TopModule>>>({
    url: '/admin/topping/list',
    data,
  })
}

//修改排序
function editOrder(data: { orderNum: number, id: number }) {
  return server.request({
    url: '/admin/topping/edit-order-num',
    data,
  })
}

//删除置顶
function delTop(id: number) {
  return server.request({
    url: '/admin/topping/del',
    data: {id},
  })
}

function addTop(data: { resourcesId: number, orderNum?: number }) {
  return server.request({
    url: '/admin/topping/add',
    data,
  })
}

function userList(data: { current: number, size?: number, email?: string }) {
  return server.request<any, ResponseModel<ListRet<User>>>({
    url: '/admin/user/list',
    data,
  })
}

function editReward(data: { id: number, money: number, month: number }) {
  return server.request({
    url: '/admin/user/edit-reward',
    data,
  })
}

function uploadResources(data: FormData) {
  return server.request<any, ResponseModel<null>>({
    url: '/admin/resources/upload-resources',
    data,
  })
}

export interface CountData {
  payingUser: number; //付款用户
  validPayingUsers: number; //有效付款用户
  activeUser: number;//活跃用户
  registeredUser: number; //当天注册用户
  registeredUserYesterday: number;//昨天注册用户
}

export const CountDataName: { [key: string]: string } = {
  activeUser: '活跃用户',
  registeredUser: '今日注册',
  registeredUserYesterday: '昨日注册',
  payingUser: '总付款用户',
  validPayingUsers: '有效付款用户',
}

function activeUser() {
  return server.request<any, ResponseModel<CountData>>({
    url: '/admin/user/active-user',
  })
}

//设置首发付费资源
function setPayResources(data: { resourcesId: number, isBuy: 1 | 2, money: number }) {
  return server.request<any, ResponseModel<null>>({
    url: '/admin/resources/set-paid-resources',
    data,
  })
}

//首发资源分享列表
function getShareResources(data: { size?: number, current: number }) {
  return server.request<any, ResponseModel<ListRet<ShareModule>>>({
    url: '/admin/share-resources/list',
    data,
  })
}

//已购买资源用户
function getPurchaseResources(data: { size?: number, current: number, userId?: number, resourcesId?: number }) {
  return server.request<any, ResponseModel<ListRet<PurchaseModule>>>({
    url: '/admin/purchase-resources/list',
    data,
  })
}

//赞赏记录
function getPayRecordList(data: { size?: number, current: number, }) {
  return server.request<any, ResponseModel<ListRet<ZanModule>>>({
    url: '/admin/pay-record/list',
    data,
  })
}

export const Api = {
  getResourceList,
  addNotice,
  editNotice,
  checkNotice,
  getNoticeList,
  getResourcesInfo,
  delTop,
  addTop,
  editOrder,
  getTopList,
  allowAll,
  login,
  logout,
  check,
  getVisits,
  edit,
  deleteItem,
  searchRecordsList,
  banList,
  banUnblock,
  userList,
  uploadResources,
  activeUser,
  setPayResources,
  getShareResources,
  getPurchaseResources,
  getPayRecordList,
  editReward,
}
