export interface BanModule {
  id: number;
  createdAt: string;
  updatedAt: string;
  banIp: string; //封禁IP
  type: Type; //封禁类型  1：2小时  2：5小时   3：1天  4：2天   5：10天   6：1个月   7：永久封禁
  unsealingTime: string; //解封时间
  status: BanStatus;//1: 已解封  2：封禁中
}

type Type = 1 | 2 | 3 | 4 | 5 | 6 | 7

export const BanType: { [K in Type]: string } = {
  1: '2h',
  2: '5h',
  3: '1d',
  4: '2d',
  5: '10d',
  6: '1m',
  7: 'forever',
}

export enum BanStatus {
  unBan = 1,
  ban = 2,
}