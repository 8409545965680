import {toast} from "react-toastify";
import {CSSProperties} from "react";

const commonStyle: CSSProperties = {
  color: '#fff',
  textAlign: 'center',
}

export const ToastStyle: { warn: CSSProperties, error: CSSProperties, success: CSSProperties, info: CSSProperties } = {
  warn: {
    ...commonStyle,
    backgroundColor: '#d5ba10',
  },
  error: {
    ...commonStyle,
    backgroundColor: '#ec5b56',
  },
  info: {
    ...commonStyle,
    backgroundColor: '#5ab5e3',
  },
  success: {
    ...commonStyle,
    backgroundColor: '#8dbd50',
  }
}

export function notice(content: string, type: 'error' | 'warn' | 'success' | 'info',) {
  let style;
  switch (type) {
    case "error":
      style = ToastStyle.error
      break;
    case "success":
      style = ToastStyle.success
      break;
    case "warn":
      style = ToastStyle.warn
      break;
    default:
      style = ToastStyle.info;
  }
  toast(
      content,
      {
        closeButton: false,
        style,
        bodyStyle: {margin: 0,alignItems: "center",},
        // icon: <span style={{}}>error</span>
      }
  );
}
