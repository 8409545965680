import React from 'react'
import ReactDOM from 'react-dom'
import './styles/index.scss'
import './styles/ReactToastify.css'
import {BrowserRouter} from 'react-router-dom'
import {App} from './pages/App'

ReactDOM.render(
  <BrowserRouter>
    <App/>
  </BrowserRouter>,
  document.getElementById('root'),
)

