import React, {FormEventHandler, useEffect, useState} from 'react'
import {ItemModule} from '../net/module/itemModule'
import {Keyword} from '../net/module/keyword'
import {CountData, Api, CountDataName} from '../net/api'
import {notice} from '../utils/notification'
import {User} from '../net/module/userInfo'
import {Header} from '../components/Header'
import '../styles/UserList.scss'
import {Pagination} from '../components/Pagination'
import {LoadingImg} from '../components/Loading'
import {useMedia} from '../hooks/useMedia'
import {Input} from '../components/Input'
import {Button} from '../components/Button'
import {PurchaseModule} from '../net/module/purchaseModule'
import {useUpdateEffect} from '../hooks/useUpdateEffect'
import {formatDate, isToday} from '../utils/utils'
import {ShareModule} from '../net/module/shareModule'
import {ZanModule} from '../net/module/zanModule'
import {Icon} from '../components/Icon'

export const UserList = () => {
  const [data, setData] = useState<User[]>([])
  const [purchaseData, setPurchaseData] = useState<PurchaseModule[]>([])
  const [shareResources, setShareResources] = useState<ShareModule[]>([])
  const [zanData, setZanData] = useState<ZanModule[]>([])
  const [isPurchase, setIsPurchase] = useState(false)
  const [isShare, setIsShare] = useState(false)
  const [isZan, setIsZan] = useState(false)
  const [current, setCurrent] = useState(1)
  const [pages, setPages] = useState(0)
  const [total, setTotal] = useState(0)
  const [shareTotal, setShareTotal] = useState(0)
  const [email, setEmail] = useState('')
  const [countData, setCountData] = useState<CountData>()
  const [loading, setLoading] = useState(false)
  const {isDesktop, isMobile} = useMedia()


  useEffect(() => {
    window.scrollTo(0, 0)
    getCountData()
    !isPurchase && !isShare && !isZan && getData()
  }, [current, isPurchase, isShare])

  useUpdateEffect(() => {
    isPurchase && getPurchaseData()
  }, [isPurchase, current])

  useEffect(() => {
    getShareData(true)
  }, [])
  useUpdateEffect(() => {
    isShare && getShareData()
  }, [isShare, current])

  useUpdateEffect(() => {
    isZan && getZanData()
  }, [isZan, current])

  const getData = async () => {
    try {
      setIsPurchase(false)
      setIsShare(false)
      setIsZan(false)
      setLoading(true)
      const {ret} = await Api.userList({current, size: 50, email})
      setData(ret.records)
      setPages(ret.pages)
      setTotal(ret.total)
      setLoading(false)
    } catch (e) {
      notice(e + '', 'error')
      setLoading(false)
    }
  }

  const getPurchaseData = async () => {
    try {
      setIsShare(false)
      setIsZan(false)
      setLoading(true)
      const {ret} = await Api.getPurchaseResources({current, size: 50})
      setPurchaseData(ret.records)
      setPages(ret.pages)
      setLoading(false)
    } catch (e) {
      notice(e + '', 'error')
      setLoading(false)
    }
  }

  const getShareData = async (isFirst: boolean = false) => {
    try {
      if (!isFirst) {
        setIsPurchase(false)
        setIsZan(false)
        setLoading(true)
      }
      const {ret} = await Api.getShareResources({current, size: 50})
      if (isFirst) {
        setShareTotal(ret.total)
      } else {
        setShareResources(ret.records)
        setPages(ret.pages)
        setShareTotal(ret.total)
        setLoading(false)
      }
    } catch (e) {
      notice(e + '', 'error')
      setLoading(false)
    }
  }

  const getZanData = async (isFirst: boolean = false) => {
    try {
      if (!isFirst) {
        setIsPurchase(false)
        setIsShare(false)
        setLoading(true)
      }
      const {ret} = await Api.getPayRecordList({current, size: 50})
      setZanData(ret.records)
      setPages(ret.pages)
      setLoading(false)
    } catch (e) {
      notice(e + '', 'error')
      setLoading(false)
    }
  }

  const getCountData = async () => {
    try {
      const {ret} = await Api.activeUser()
      setCountData(ret)
    } catch (e) {
      notice(e + '', 'error')
    }
  }

  const onSubmit: FormEventHandler = (e) => {
    e.preventDefault()
    getData()
  }

  const renderZan = () => {
    if (!isZan) return null
    return (
      <>
        {
          zanData.map(item => {
            return (
              <div
                className={'user-item'}
                key={item.id}
                style={{
                  backgroundColor: item.money >= 120 ?
                    '#ffecec' :
                    item.money >= 70 ?
                      '#eaf9fd' :
                      item.money >= 38 ?
                        '#fdf9e7' :
                        isToday(item.createdAt) ? '#f9faff' :
                          'transparent',
                }}
              >
                <span className={'id'}>{item.userId}</span>
                <Icon style={{margin: isDesktop ? '0 0 0 10px' : '0 10px 0 0'}} size={isDesktop ? 25 : 20} name={item.type === 1 ? 'wxpay' : 'alipay'}/>
                <a
                  className="name"
                  href={'https://dapanso.com/user/' + item.userId}
                  target={'_blank'}
                  style={{}}
                >{item.user.name}</a>
                <div style={{display: 'flex', flex: isDesktop ? 1.5 : 1.2, flexDirection: isDesktop ? 'row' : 'column', alignItems: isDesktop ? 'center' : 'flex-start'}}>
                  <a
                    className="email"
                    href={item.resources?.name ? 'http://localhost:3001/detail/' + item.resourcesId : 'https://dapanso.com/user/' + item.userId}
                    target={'_blank'}
                    style={{flex: 1, marginBottom: !isDesktop ? 3 : 0, color: '#0a607c', maxWidth: isDesktop ? 450 : 125, paddingRight: 20}}
                  >{item.resources?.name || '[个人中心]'}</a>
                  <a
                    className="time"
                    style={{color: '#0a607c', flex: 'none', width: 100}}
                    href={item.resources?.url.split(';')[0] || ('https://dapanso.com/user/' + item.userId)}
                    target={'_blank'}
                  >
                    云盘链接
                  </a>
                  <span
                    className="time"
                    style={{width: 100, flex: 'none'}}
                  >{formatDate(item.createdAt, 'MM/DD HH:mm')}</span>
                </div>
                {/*<span className="likes">{item.money}</span>*/}
                <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: 50}}>
                  <span className={'reward'}>{`￥${item.money}`}</span>
                  {/*<span className="time">{formatDate(item.buyTime, 'YYYY/MM/DD HH:mm')}</span>*/}
                </div>
              </div>
            )
          })
        }
      </>
    )
  }

  const renderPurchase = () => {
    if (isPurchase && purchaseData.length) {
      return (
        <>
          {
            purchaseData.map(item => {
              return (
                <div
                  className={'user-item'}
                  key={item.id}
                >
                  <span className={'id'}>{item.userId}</span>
                  <a
                    className="name"
                    href={'https://dapanso.com/user/' + item.id}
                    target={'_blank'}
                    style={{}}
                  >{item.user.name}</a>
                  <div style={{display: 'flex', flex: isDesktop ? 2 : 1.5, flexDirection: isDesktop ? 'row' : 'column', alignItems: isDesktop ? 'center' : 'flex-start'}}>
                    <a
                      className="email"
                      href={'https://dapanso.com/s/' + item.resourcesId}
                      target={'_blank'}
                      style={{marginBottom: !isDesktop ? 3 : 0, color: '#0a607c'}}
                    >{item.resources.name}</a>
                    <span className="time">{formatDate(item.buyTime, 'MM/DD HH:mm')}</span>
                    {/*<a*/}
                    {/*    className="time"*/}
                    {/*    style={{color: '#0a607c', maxWidth: isDesktop ? 200 : 125}}*/}
                    {/*    href={item.resources.url}*/}
                    {/*    target={'_blank'}*/}
                    {/*>*/}
                    {/*  {item.resources.name}*/}
                    {/*</a>*/}
                  </div>
                  {/*<span className="likes">{item.money}</span>*/}
                  <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
                    <span className={'reward'} style={{fontWeight: item.money < 3 ? 'normal' : 'bold', color: item.money < 3 ? '#666' : '#f53105'}}>{`￥${item.money}`}</span>
                    {/*<span className="time">{formatDate(item.buyTime, 'YYYY/MM/DD HH:mm')}</span>*/}
                  </div>
                </div>
              )
            })
          }
        </>
      )
    } else {
      return null
    }
  }

  const renderShare = () => {
    if (isShare && shareResources.length) {
      return (
        <>
          {
            shareResources.map(item => {
              return (
                <div
                  className={'user-item'}
                  key={item.id}
                >
                  <span className={'id'}>{item.userId}</span>
                  <a
                    className="name"
                    href={'https://dapanso.com/user/' + item.userId}
                    target={'_blank'}
                  >{item.user.name}</a>
                  <div style={{display: 'flex', flex: isDesktop ? 1.5 : 1.2, flexDirection: isDesktop ? 'row' : 'column', alignItems: isDesktop ? 'center' : 'flex-start'}}>
                    <a
                      className="email"
                      href={'https://dapanso.com/s/' + item.resourcesId}
                      target={'_blank'}
                      style={{marginBottom: !isDesktop ? 3 : 0, color: '#0a607c'}}
                    >{item.resources.name}</a>
                    <span className="time">{formatDate(item.createdAt, 'MM/DD HH:mm')}</span>
                    {/*<a*/}
                    {/*    className="time"*/}
                    {/*    style={{color: '#0a607c', maxWidth: isDesktop ? 200 : 125}}*/}
                    {/*    href={item.resources.url}*/}
                    {/*    target={'_blank'}*/}
                    {/*>*/}
                    {/*  {item.resources.name}*/}
                    {/*</a>*/}
                  </div>
                  {/*<span className="likes">{item.money}</span>*/}
                  <div style={{display: 'flex', width: 100, flexDirection: 'column', alignItems: 'flex-end'}}>
                    <span className={'reward'} style={{fontWeight: 'normal', color: '#666'}}>{item.clickNumber} / <span style={{fontSize: 12}}>{item.standardClick}</span></span>
                    {/*<span className="time">{formatDate(item.buyTime, 'YYYY/MM/DD HH:mm')}</span>*/}
                  </div>
                </div>
              )
            })
          }
        </>
      )
    } else {
      return null
    }
  }

  const minWidth = !isMobile ? 110 : window.innerWidth * 0.9 / 4
  return (
    <>
      <Header onSearch={getData}/>
      <main className="userList">
        <div className="user-left">
          <div
            style={{
              position: 'sticky',
              justifyContent: 'flex-start',
              flexDirection: 'column',
              top: isDesktop ? 55 : 50,
              background: '#fff',
              borderBottom: '1px solid #f2f8fc',
              paddingBottom: 10,
              display: 'flex',
            }}
          >
            <div style={{display: 'flex', flex: 1, flexWrap: 'wrap'}}>
              <div style={{display: 'flex', flex: 1, minWidth, padding: '5px 0', marginTop: 10, flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}>
                <span style={{color: '#222', fontSize: isDesktop ? 16 : 14, fontWeight: 500}}>{total}</span>
                <span style={{color: '#888', fontSize: 12, marginTop: 5}}>总用户</span>
              </div>
              {
                Object.keys(CountDataName).map((key) =>
                  <div
                    key={key}
                    style={{
                      flex: 1, display: 'flex', padding: '5px 0', marginTop: 10,
                      minWidth, flexDirection: 'column', alignItems: 'center', justifyContent: 'center',
                    }}
                  >
                    {/*// @ts-ignore*/}
                    <span style={{color: '#222', fontSize: isDesktop ? 16 : 14, fontWeight: 500}}>{countData ? countData[key] : 0}</span>
                    <span style={{color: '#888', fontSize: 12, marginTop: 5}}>{CountDataName[key]}</span>
                  </div>)
              }
              <div
                style={{flex: 1, display: 'flex', padding: '5px 0', minWidth, marginTop: 10, flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}}
              >
                <span style={{color: '#222', fontSize: isDesktop ? 16 : 14, fontWeight: 500}}>{shareTotal}</span>
                <span style={{color: '#888', fontSize: 12, marginTop: 5}}>总分享</span>
              </div>
            </div>
            <div style={{display: 'flex', flexWrap: 'wrap', padding: 15, paddingBottom: 10, alignItems: 'center'}}>
              <Button onClick={() => setIsZan(!isZan)} color={isZan ? '' : '#ccc'} style={{width: 80, fontSize: 13, borderRadius: 2, height: 30}} text={'赞赏列表'}/>
              <Button onClick={() => setIsPurchase(!isPurchase)} color={isPurchase ? '' : '#ccc'} style={{width: 80, fontSize: 13, borderRadius: 2, height: 30, marginLeft: 5}} text={'首发付款'}/>
              <Button onClick={() => setIsShare(!isShare)} color={isShare ? '' : '#ccc'} style={{width: 80, fontSize: 13, borderRadius: 2, height: 30, marginLeft: 5}} text={'首发分享'}/>
              <div style={{flex: 1}}/>
              <form onSubmit={onSubmit} style={{display: 'flex', marginTop: 5}}>
                <Input placeholder={'邮箱搜索'} value={email} onChange={setEmail} style={{minWidth: 160, fontSize: 13, border: '1px solid #ccc', height: 30, padding: '0 10px'}}/>
                <Button onClick={getData} text={'搜索'} style={{minWidth: 70, borderRadius: 0, fontSize: 13, marginLeft: -1, height: 32}}/>
              </form>
            </div>
          </div>
          {
            loading || (!isPurchase && data.length === 0) || (isPurchase && purchaseData.length === 0) || (isShare && shareResources.length === 0) ?
              <div className={'page-loading-wrap'} style={{marginTop: 0}}>
                {
                  !loading ?
                    <span className={'no-tips'}>{'暂无数据'}</span> :
                    <LoadingImg/>
                }
              </div> :
              <div className={'content'}>
                {
                  isPurchase ?
                    renderPurchase() :
                    isShare ?
                      renderShare() :
                      isZan ?
                        renderZan() :
                        <>
                          {
                            data.map(item => (
                              <div
                                className={'user-item'}
                                key={item.id}
                                style={{
                                  backgroundColor:
                                    isToday(item.createdAt) ? '#f9faff' :
                                      'transparent',
                                }}
                              >
                                <span className={'id'}>{item.id}</span>
                                <a
                                  className="name"
                                  href={'https://dapanso.com/user/' + item.id}
                                  target={'_blank'}
                                >{item.name}</a>
                                <div style={{display: 'flex', flex: isDesktop ? 2 : 1.5, flexDirection: isDesktop ? 'row' : 'column', alignItems: isDesktop ? 'center' : 'flex-start'}}>
                                  <span className="email" style={{marginBottom: !isDesktop ? 3 : 0}}>{item.email}</span>
                                  <span className="time" style={{flex: 1}}> {formatDate(item.doAt, 'MM/DD HH:mm')} - <span
                                    style={{
                                      fontSize: 12,
                                      color: '#aaa',
                                    }}
                                  >{formatDate(item.createdAt, 'MM/DD HH:mm')} </span></span>
                                  <a
                                    className="time"
                                    style={{color: '#0a607c', width: isDesktop ? 200 : 150}}
                                    href={item.refer}
                                    target={'_blank'}
                                  >
                                    {item.refer?.replaceAll('http://', '').replaceAll('https://', '')}
                                  </a>
                                </div>
                                {/*<span className="likes">{item.likes}</span>*/}
                                <div style={{display: 'flex', width: 100, flexDirection: 'column', alignItems: 'flex-end'}}>
                                  <span className={'reward'} style={{color: item.totalReward > 0 ? '#f53105' : '#f5b005'}}>{item.totalReward > 0 ? `￥${item.totalReward}` : ''}</span>
                                  <span className="time">{formatDate(item.endTime, 'MM/DD HH:mm')}</span>
                                </div>
                              </div>
                            ))
                          }
                        </>
                }
              </div>
          }
          <Pagination
            pages={pages}
            current={current}
            onPageChange={setCurrent}
          />
        </div>
        {/*<div className="user-right"/>*/}
      </main>
    </>
  )
}
