import {Icon} from './Icon'
import React, {ChangeEvent, ChangeEventHandler, FormEventHandler, useEffect, useMemo, useRef, useState} from 'react'
import '../styles/components/Header.scss'
import {Link, NavLink, useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import {Input} from './Input'
import {eventBus, hideLoading, showLoading} from '../utils/eventBus'
import {UserInfo} from '../net/module/userInfo'
import {getNoticeData, getUserInfo, removeUserInfo, setUserInfo as setUserInfoStorage} from '../utils/utils'
import {Api} from '../net/api'
import {notice} from '../utils/notification'
import {ItemStatus} from '../constants'
import {useMedia} from '../hooks/useMedia'

interface Props {
  onSearch?(text?: string): void;
}

export const Header = ({onSearch}: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const {isMobile, isDesktop} = useMedia()
  const [searchParams, setSearchParams] = useSearchParams()
  const textRef = useRef(searchParams.get('keyword') || '')
  const [userInfo, setUserInfo] = useState<UserInfo | null>(getUserInfo())
  const [visits, setVisits] = useState(0)
  const inputRef = useRef<HTMLInputElement | null>(null)
  const fileRef = useRef<HTMLInputElement | null>(null)
  const isSearchPage = location.pathname.indexOf('search') !== -1
  const isUploadPage = location.pathname.indexOf('upload') !== -1
  const isUserPage = location.pathname.indexOf('user') !== -1
  const isNoticePage = location.pathname.indexOf('notice') !== -1
  const isRewardPage = location.pathname.indexOf('reward') !== -1
  const isBanPage = location.pathname.indexOf('ban') !== -1
  const isTopPage = location.pathname.indexOf('top') !== -1
  let isUnMount = false

  useEffect(() => {
    Api.getVisits().then(res => !isUnMount && setVisits(res.ret.day)).catch(e => notice(e + '', 'error'))
    return () => {
      isUnMount = true
    }
  }, [])

  useEffect(() => {
    if (!userInfo) {
      navigate('/', {replace: true})
    }
    eventBus.on('user', (userInfo: UserInfo | null) => {
      setUserInfo(userInfo)
      if (userInfo) {
        setUserInfoStorage(userInfo)
      } else {
        removeUserInfo()
        //如果是未登录的，且在个人中心页，强制跳转到首页
        navigate('/', {replace: true})
      }
    })
    return () => {
      eventBus.off('user')
    }
  }, [])
  const onTextChange = (text: string) => {
    textRef.current = text
  }
  const onSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault()
    inputRef.current?.blur()
    _onSearch()
  }
  const _onSearch = () => {
    //setSearchParams内部也是通过navigate实现的跳转，让url发生改变，只会在当前路由后追加参数跳转
    if (!isSearchPage) {
      navigate(`/search?keyword=${textRef.current}&page=1&status=${searchParams.get('status') || ''}&type=${searchParams.get('type') || ''}`)
    } else {
      setSearchParams({keyword: textRef.current, status: searchParams.get('status') || '', page: searchParams.get('page') || '1', type: searchParams.get('type') || ''})
      //如果当前是搜索页，且keyword和page都没改，点击按钮，或重新敲击回车键，不会触发页面中的重新刷新，故手动刷新
      onSearch?.(textRef.current)
    }
  }
  const reset = () => {
    if (!isSearchPage) {
      navigate(`/search?keyword=&page=1&type=&status=` + ItemStatus.passed)
    } else {
      setSearchParams({keyword: '', status: ItemStatus.passed + '', page: '1', type: ''})
      window.location.reload()
    }
  }

  const choseFile = () => {
    if (fileRef.current) {
      //防止无法选中相同文件
      fileRef.current.value = ''
      fileRef.current.click()
    }
  }

  const uploadResources: ChangeEventHandler<HTMLInputElement> = async (e) => {
    try {
      showLoading()
      let formData = new FormData()
      let file = e.target.files?.[0]
      if (file) {
        formData.append('file', file)
      }
      await Api.uploadResources(formData)
      hideLoading()
      notice('上传成功', 'success')
    } catch (e) {
      hideLoading()
      notice(e + '', 'error')
    }
  }
  return (
    <>
      <div className={'header-wrap'}>
        <div className={'logo'} onClick={reset}>
          <Icon name={'logo'} size={55}/>
        </div>
        <header className={'header'}>
          <div className="header-left-wrap">
            <form onSubmit={onSubmit} className={'header-left'}>
              <Input ref={inputRef} type="search" defaultValue={textRef.current} placeholder={'大盘搜 - 大大的盘任你搜'} onChange={onTextChange}/>
              <Icon color={textRef.current ? '#6A90FF' : '#c3c3c3'} name={'search'} size={'1.5rem'} onClick={_onSearch}/>
            </form>
            {
              isDesktop ?
                <>
                  <NavLink className={({isActive}) => 'nav-item ' + (isActive ? 'active' : '')} to={'/search?status=1'}>资源</NavLink>
                  <NavLink className={({isActive}) => 'nav-item ' + (isActive ? 'active' : '')} to={'/top'}>置顶</NavLink>
                  <NavLink className={({isActive}) => 'nav-item ' + (isActive ? 'active' : '')} to={'/user'}>用户</NavLink>
                  <NavLink className={({isActive}) => 'nav-item ' + (isActive ? 'active' : '')} to={'/reward'}>赞赏</NavLink>
                  {/*<NavLink className={({isActive}) => 'nav-item ' + (isActive ? 'active' : '')} to={'/notice'}>公告</NavLink>*/}
                  <NavLink className={({isActive}) => 'nav-item ' + (isActive ? 'active' : '')} to={'/ban'}>封禁</NavLink>
                </> : null
            }
          </div>
          <div className="header-right">
            {
              userInfo ?
                <span className={'user-text'}>访问数_{visits}</span> :
                null
            }
            {
              !isUploadPage /*&& !isUserPage && !isTopPage*/ ?
                <div className="up-section">
                  <Link className={'btn-wrap'} to={'/publish'}>
                    <Icon name={'upload'}/>
                    <span>发布</span>
                  </Link>
                  {/*<div className={'btn-wrap'} style={{backgroundColor: '#70b384', marginLeft: 10}} onClick={choseFile}>*/}
                  {/*  <Icon name={'share'} color={'#fff'}/>*/}
                  {/*  <span>上传EXCEL</span>*/}
                  {/*  <input*/}
                  {/*    id="file"*/}
                  {/*    type="file"*/}
                  {/*    name="singlePhoto"*/}
                  {/*    ref={fileRef}*/}
                  {/*    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"*/}
                  {/*    onChange={uploadResources}*/}
                  {/*    style={{display: 'none'}}*/}
                  {/*  />*/}
                  {/*</div>*/}
                </div> :
                null
            }
          </div>
        </header>
      </div>
      <div className={'bottom-tab'}>
        <Link to={'/search'}>
          <Icon name={'home'} color={getActiveColor(isSearchPage)} size={20}/>
          <span style={{color: getActiveColor(isSearchPage)}}>主页</span>
        </Link>
        <Link to={'/top'}>
          <Icon name={'top'} color={getActiveColor(isTopPage)} size={20}/>
          <span style={{color: getActiveColor(isTopPage)}}>置顶</span>
        </Link>
        <Link to={'/user'}>
          <Icon name={'user'} color={getActiveColor(isUserPage)} size={20}/>
          <span style={{color: getActiveColor(isUserPage)}}>用户</span>
        </Link>
        <Link to={'/reward'}>
          <Icon name={'ban'} color={getActiveColor(isRewardPage)} size={20}/>
          <span style={{color: getActiveColor(isRewardPage)}}>赞赏</span>
        </Link>
        {/*<Link to={'/notice'}>*/}
        {/*  <Icon name={'notice'} color={getActiveColor(isNoticePage)} size={20}/>*/}
        {/*  <span style={{color: getActiveColor(isNoticePage)}}>公告</span>*/}
        {/*</Link>*/}
        {/*<Link to={'/ban'}>*/}
        {/*  <Icon name={'ban'} color={getActiveColor(isBanPage)} size={20}/>*/}
        {/*  <span style={{color: getActiveColor(isBanPage)}}>封禁</span>*/}
        {/*</Link>*/}
      </div>
    </>
  )
}

function getActiveColor(isActive: boolean) {
  return isActive ? '#6A90FF' : '#888'
}
