export enum Type {
  video = 1,
  pic = 2,
  txt = 3,
  exe = 4,
  music = 5,
  other = 6,
}

export const DataType: { [key: number | string]: any } = {
  [Type.video]: {value: Type.video, icon: 'video', text: '视频', color: '#8095FF'},
  [Type.pic]: {value: Type.pic, icon: 'pic', text: '图片', color: '#dd9e13'},
  [Type.txt]: {value: Type.txt, icon: 'txt', text: '文档', color: '#57b580'},
  [Type.exe]: {value: Type.exe, icon: 'exe', text: '软件', color: '#da655e'},
  [Type.music]: {value: Type.music, icon: 'music', text: '音频', color: '#8450EA'},
  [Type.other]: {value: Type.other, icon: 'other', text: '其他', color: '#ACACAC'},
}

export enum ItemStatus {
  waiting = 0,//待审核
  passed = 1,//审核通过
  reject = 2,//审核拒绝
  failed = 3,//资源失效
}

export const DataStatus = {
  [ItemStatus.waiting]: {value: ItemStatus.waiting, icon: 'video', text: '待审', color: '#8095FF'},
  [ItemStatus.passed]: {value: ItemStatus.passed, icon: 'pic', text: '通过', color: '#57b580'},
  [ItemStatus.reject]: {value: ItemStatus.reject, icon: 'txt', text: '拒绝', color: '#da655e'},
  [ItemStatus.failed]: {value: ItemStatus.failed, icon: 'exe', text: '失效', color: '#ACACAC'},
}

export enum NoticeStatus {
  passed = 1,//正常
  reject = 2,//禁用
}

export const NoticeDataStatus = {
  [NoticeStatus.passed]: {value: NoticeStatus.passed, icon: 'pic', text: '已通过', color: '#57b580'},
  [NoticeStatus.reject]: {value: NoticeStatus.reject, icon: 'txt', text: '禁用中', color: '#da655e'},
}