import React, {useEffect, useRef, useState} from 'react';
import '../styles/NoticeList.scss';
import {ItemStatus} from "../constants";
import {Announcement} from "../components/Announcement";
import {Header} from "../components/Header";
import {Api} from "../net/api";
import {notice} from "../utils/notification";
import {setNoticeData} from "../utils/utils";
import {Pagination} from "../components/Pagination";
import {useUpdateEffect} from "../hooks/useUpdateEffect";
import {LoadingImg} from "../components/Loading";
import {useSearchParams} from "react-router-dom";
import {Notice} from "../net/module/notice";
import {eventBus} from "../utils/eventBus";

export const NoticeList = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [status, setStatus] = useState<ItemStatus>(ItemStatus.waiting)
  const [data, setData] = useState<Notice[]>([])
  const [current, setCurrent] = useState(1)
  const [pages, setPages] = useState(0);
  const keywordRef = useRef(searchParams.get('keyword') ?? '')
  const [loading, setLoading] = useState(false)
  let isUnmount = false;
  useUpdateEffect(() => {
    //注意页码跳转也会触发更新，需排除页码的影响
    const newKeyword = searchParams.get('keyword') ?? ''
    if (keywordRef.current !== newKeyword) {
      keywordRef.current = newKeyword
    }
  }, [searchParams])
  useEffect(() => {
    window.scrollTo(0, 0);
    setSearchParams({keyword: keywordRef.current, page: current + ''})
    getData()
  }, [current])

  useEffect(() => {
    eventBus.off('notice')
    eventBus.on('notice', (notice: Notice) => {
      setData(prev => prev.map(item => {
        if (item.id === notice.id) return notice
        return item
      }))
    })
    return () => {
      eventBus.off('notice')
      isUnmount = true
    }
  }, [])

  useUpdateEffect(() => resetToSearch(), [status])

  const resetToSearch = () => {
    //重置pagination
    setPages(0)
    //重新搜索前重置页码
    //切换type，重置页码
    //如果页码不为1，重置后触发current的更新监听，会自动请求
    if (current !== 1) {
      setCurrent(1)
    } else {
      window.scrollTo(0, 0);
      setSearchParams({keyword: keywordRef.current, page: '1'})
      getData()
    }
  }

  const getData = async (text?: string) => {
    //text有值，且和存的不一样，代表是重新搜索的关键字
    if (text && text !== keywordRef.current) {
      setSearchParams({keyword: text, page: '1'})
      if (current !== 1) {
        setCurrent(1)
        return
      }
    }
    // const keyword = text ?? keywordRef.current
    // if (!keyword) {
    //   notice('请输入关键字搜索', 'info')
    //   return
    // }
    try {
      setLoading(true)
      const {ret} = await Api.getNoticeList(current)
      if (!isUnmount) {
        setData(ret.records)
        setPages(ret.pages)
        setNoticeData(ret.records)
        setLoading(false)
      }
    } catch (e) {
      if (!isUnmount) {
        notice(e + '', 'error')
        setLoading(false)
      }
    }
  }

  return (
      <>
        <Header onSearch={getData}/>
        <div className="notice">
          <main className={'main'}>
            <div className={'main-left'}>
              {
                loading || data.length === 0 ?
                    <div className={'page-loading-wrap'}>
                      {
                        !loading ?
                            <span className={'no-tips'}>{'暂无数据'}</span> :
                            <LoadingImg/>
                      }
                    </div> :
                    <div className={'list'}>
                      {
                        data.map((item, index) => <Announcement data={item} key={item.id}/>)
                      }
                    </div>
              }
              <Pagination
                  pages={pages}
                  current={current}
                  onPageChange={setCurrent}
              />
            </div>
            <div className="main-right"/>
          </main>
        </div>
      </>
  );
}