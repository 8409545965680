import React, { useEffect, useRef, useState } from 'react'
import '../styles/Search.scss'
import { Item } from '../components/Item'
import { SearchList } from '../components/SearchList'
import { DataStatus, ItemStatus } from '../constants'
import { Header } from '../components/Header'
import { Api } from '../net/api'
import { ItemModule } from '../net/module/itemModule'
import { notice } from '../utils/notification'
import { Pagination } from '../components/Pagination'
import { useUpdateEffect } from '../hooks/useUpdateEffect'
import { LoadingImg } from '../components/Loading'
import { useSearchParams } from 'react-router-dom'
import { eventBus } from '../utils/eventBus'
import { Keyword } from '../net/module/keyword'
import { Icon } from '../components/Icon'
import copy from 'copy-to-clipboard'
import { useMedia } from '../hooks/useMedia'
import { Button } from '../components/Button'

export const Search = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const [status, setStatus] = useState<ItemStatus>(parseInt(searchParams.get('status') || '0'))
  const [data, setData] = useState<ItemModule[]>([])
  const [type, setType] = useState(searchParams.get('type') || '')
  const [isBuy, setIsBuy] = useState(false)
  const [current, setCurrent] = useState(parseInt(searchParams.get('page') || '1'))
  const [pages, setPages] = useState(0)
  const keywordRef = useRef(searchParams.get('keyword') || '')
  const [loading, setLoading] = useState(false)
  const { isMobile } = useMedia()
  let isUnmount = false

  useUpdateEffect(() => {
    eventBus.off('check')
    eventBus.on('check', ({ id, status }: { id: number, status: ItemStatus }) => {
      setData(prev => prev.filter(item => item.id !== id))
    })
    eventBus.off('delete')
    eventBus.on('delete', ({ id }: { id: number }) => {
      setData(prev => prev.filter(item => item.id !== id))
    })
    return () => {
      eventBus.off('check')
      eventBus.off('delete')
      isUnmount = true
    }
  }, [data])

  useUpdateEffect(() => {
    //注意页码跳转也会触发更新，需排除页码的影响
    const newKeyword = searchParams.get('keyword') ?? ''
    if (keywordRef.current !== newKeyword) {
      keywordRef.current = newKeyword
    }
  }, [searchParams])

  useEffect(() => {
    window.scrollTo(0, 0)
    setSearchParams({ keyword: keywordRef.current, status: status + '', page: current + '', type })
    getData()
  }, [current])

  useUpdateEffect(() => {
    setData([])
    resetToSearch()
  }, [status, type, isBuy])

  const resetToSearch = () => {
    //重置pagination
    setPages(0)
    //重新搜索前重置页码
    //切换type，重置页码
    //如果页码不为1，重置后触发current的更新监听，会自动请求
    if (current !== 1) {
      setCurrent(1)
    } else {
      window.scrollTo(0, 0)
      setSearchParams({ keyword: keywordRef.current, status: status + '', page: '1', type })
      getData()
    }
  }

  const getData = async (text?: string) => {
    //text有值，且和存的不一样，代表是重新搜索的关键字
    if (text !== undefined && text !== keywordRef.current) {
      setSearchParams({ keyword: text, status: status + '', page: '1', type })
      if (current !== 1) {
        setCurrent(1)
        return
      }
    }
    const keyword = text ?? keywordRef.current
    try {
      setLoading(true)
      const { ret } = await Api.getResourceList({ name: keyword, size: 20, type: type ? parseInt(type) : undefined, current, status, isBuy: isBuy ? 1 : undefined })
      if (!isUnmount) {
        setData(ret.records)
        setPages(ret.pages)
        setLoading(false)
      }
    } catch (e) {
      if (!isUnmount) {
        notice(e + '', 'error')
        setLoading(false)
      }
    }
  }

  const onEdit = (data: ItemModule) => {
    setData(prev => prev.map(item => {
      if (item.id === data.id) return data
      return item
    }))
  }

  const allowAll = async () => {
    if (window.confirm(`确定通过所有上传资源？`)) {
      try {
        await Api.allowAll()
        if (!isUnmount) {
          setData([])
          setPages(0)
        }
      } catch (e) {
        notice(e + '', 'error')
      }
    }
  }
  return (
    <>
      <Header onSearch={getData} />
      <div className="search">
        <main className={'main'}>
          <div className={'main-left'}>
            <div className={'type-wrap'}>
              {Object.values(DataStatus).map((item, index) => {
                const isActive = status === item.value
                return (
                  <div
                    key={item.value}
                    className={'type-item ' + (isActive ? 'active' : '')}
                    onClick={isActive ? undefined : () => setStatus(item.value)}
                    style={isActive ? { backgroundColor: item.color } : undefined}
                  >
                    {item.text}
                  </div>
                )
              })}
              <div
                className={'type-item user-type ' + (type ? 'active' : '')}
                onClick={() => setType(prev => prev ? '' : '1')}
              >
                {type === '1' ? '真实用户' : '全部用户'}
              </div>
              <div
                className={'type-item buy-type ' + (isBuy ? 'active' : '')}
                onClick={() => setIsBuy(!isBuy)}
              >
                {isBuy ? '首发资源' : '全部资源'}
              </div>
            </div>
            {
              status === ItemStatus.waiting && data.length ?
                <Button style={{ width: 100, height: 40, marginTop: 20, marginLeft: 5 }} text={'全部通过'} onClick={allowAll} /> :
                null
            }
            {
              loading || data.length === 0 ?
                <div className={'page-loading-wrap'}>
                  {
                    !loading ?
                      <span className={'no-tips'}>{'暂无数据'}</span> :
                      <LoadingImg />
                  }
                </div> :
                <div className={'list'}>
                  {
                    data.map((item, index) => <Item key={item.id} data={item} onEdit={onEdit} />)
                  }
                </div>
            }
            <Pagination
              pages={pages}
              current={current}
              onPageChange={setCurrent}
            />
          </div>
          <SearchList />
        </main>
      </div>
    </>
  )
}
