import '../styles/components/Button.scss'
import {CSSProperties, MouseEventHandler} from "react";

interface Props {
  text: string;
  disabled?: boolean;
  color?: string;
  style?: CSSProperties;

  onClick?: MouseEventHandler;
}

export const Button = ({text, style, color, onClick, disabled = false}: Props) => {
  return (
      <div
          style={{...style, backgroundColor: color}}
          className={'button'}
          onClick={disabled ? undefined : onClick}
          aria-disabled={disabled}
      >
        <span>{text}</span>
      </div>
  )
}