import {Icon} from "./Icon";
import {Label} from "./Label";
import {useMedia} from "../hooks/useMedia";

export const ClickLabel = ({text}: { text: string }) => {
  const {isDesktop} = useMedia()
  if (!parseInt(text)) return null
  const isRed = parseInt(text) > 100
  return (
      <Label
          color={isRed ? '#d81e06' : '#888888'}
          text={text}
          icon={<Icon name={isRed ? 'fire' : 'fire-normal'} size={isDesktop ? 18 : 15}/>}
          styleWrap={{marginLeft: 5}}
      />
  )
}