import {Icon} from "./Icon";
import '../styles/components/ItemType.scss'
import {DataType, Type} from "../constants";
import {useMediaQuery} from 'react-responsive'
import {Label} from "./Label";
import {useMedia} from "../hooks/useMedia";
import {useEffect, useState} from "react";
import {ItemModule} from "../net/module/itemModule";
import {Api} from "../net/api";
import {notice} from "../utils/notification";

interface Props {
  data: ItemModule;
  onChange?: (newType: Type) => void;
}

export const ItemType = ({data, onChange}: Props) => {
  const [type, setType] = useState(data.type)
  const {icon, text, color,} = DataType[type]
  const {isDesktop} = useMedia()
  const [show, setShow] = useState(false)
  useEffect(() => {
    const handler = () => {
      setShow(false)
    }
    document.addEventListener('click', handler);
    return () => {
      document.removeEventListener('click', handler)
    }
  }, [])

  const changeType = async (type: string) => {
    try {
      const newType = parseInt(type)
      const changeData = {...data, type: newType,} as ItemModule
      setType(newType)
      setShow(false)
      const {ret} = await Api.edit(changeData)
      onChange?.(newType)
    } catch (e) {
      setType(data.type)
      setShow(false)
      notice(e + '', 'error')
    }
  }
  return (
      <div style={{position: 'relative', overflow: "visible"}}>
        <Label
            onClick={() => setShow(true)}
            color={color}
            text={text}
            icon={<Icon size={isDesktop ? 18 : 16} name={icon}/>}
        />
        {
          show ?
              <div style={{
                position: "absolute",
                top: 30, left: 0, background: '#fff',
                boxShadow: '0 0 5px #0002', zIndex: 99,
                right: -50, padding: '5px 10px',
              }}>
                {
                  Object.keys(DataType).map(item => {
                        const i = DataType[item]
                        return <Label
                            key={item}
                            styleWrap={{marginBottom: 5, marginTop: 5}}
                            onClick={() => changeType(item)}
                            color={i.color}
                            text={i.text}
                            icon={<Icon size={isDesktop ? 18 : 16} name={i.icon}/>}
                        />;
                      }
                  )
                }
              </div> : null
        }
      </div>
  )
}