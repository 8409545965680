import '../styles/Upload.scss'
import axios from 'axios'
import React, {useState} from 'react'
import {Button} from '../components/Button'
import {Header} from '../components/Header'
import {SearchList} from '../components/SearchList'
import {DataType, Type} from '../constants'
import {hideLoading, showLoading} from '../utils/eventBus'
import {notice} from '../utils/notification'

export const Upload = () => {
  const [type, setType] = useState(Type.video)
  const [text, setText] = useState('')
  const upload = async () => {
    showLoading()
    const texts = text.split('\n').filter(item => item)
    let temp = []
    for (let i = 0; i < texts.length / 2; i++) {
      temp.push(texts.slice(i * 2, i * 2 + 2))
    }
    Promise
      .all(temp.map(async item => {
          return axios.request({
            baseURL: '/upload',
            url: '/api/resources/save',
            headers: {
              auth: '61ff89f2d1dc748dfdssasdasffgf378e948a6870586f3e5023f06',
            },
            method: 'post',
            data: {
              files: [{'name': item[0], 'type': 'file'}],
              url: item[1],
              type,
            },
          })
        }),
      )
      .then(res => {
        hideLoading()
        notice('上传成功', 'success')
        setText('')
      })
      .catch(err => {
        hideLoading()
        notice(err.message, 'error')
      })
  }

  return (
    <>
      <Header/>
      <div className={'upload'}>
        <div className={'up-left'}>
          <div className={'select-wrap'}>
            {Object.values(DataType).map((item, index) => {
              const isActive = type === item.value
              return (
                <div
                  key={item.value}
                  className={'type-item ' + (isActive ? 'active' : '')}
                  onClick={() => setType(item.value)}
                  style={isActive ? {backgroundColor: item.color} : undefined}
                >
                  {item.text}
                </div>
              )
            })}
          </div>
          <textarea
            style={{height: '50vh'}}
            value={text}
            onChange={(e) => setText(e.target.value)}
          />
          <Button
            style={{alignSelf: 'stretch'}}
            text={'发布'}
            onClick={upload}
            // disabled={!editorState || editorState.isEmpty()}
          />
        </div>
        <div className={'up-right main'}>
          <SearchList/>
        </div>
      </div>
    </>
  )
}
