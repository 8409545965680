import copy from 'copy-to-clipboard'
import {ItemStatus} from '../constants'
import {Api} from '../net/api'
import {ItemModule} from '../net/module/itemModule'
import {eventBus} from '../utils/eventBus'
import {notice} from '../utils/notification'


function encodeStr(str: string) {
  if (!str) return

  let random = (lower: number, upper: number) => {
    return Math.floor(Math.random() * (upper - lower + 1)) + lower
  }

  let arr = str.split('').map((item: string) => {
    return item.charCodeAt(0).toString(8)
  })

  arr.reverse()//反序数组
  arr = arr.join('_').split('')//暂时使用 _ 分割字符串;
  let num = 0
  while (num < str.length) {
    let r = String.fromCharCode(random(97, 122))//生成用于混淆的  的 小写字母
    arr.splice(random(0, arr.length), 0, r)
    num++
  }
  return arr.join('').replace(/_/ig, (str: string) => {
    return String.fromCharCode(random(65, 90))
  })//将分割符 _ 替换为随机的 大写字母
}

function decodeStr(str: string) {
  if (!str) return ''
  let temp: string[] = []
  str.split('').forEach((item: string) => {
    let code = item.charCodeAt(0)
    if (code <= 90 && code >= 65) {
      item = '_'//将作为分割用的 随机大写字母 统一为 _  以便切割
      temp.push(item)
    } else if (code <= 57 && code >= 48) {
      temp.push(item)//提取 数字
    }
  })
  temp = temp.join('').split('_')
  temp.reverse()
  let res = temp.map(function (item) {
    return String.fromCharCode(parseInt(item, 8))
  })
  return res.join('')
}


export const useShareClick = (data?: ItemModule) => {
  const onShare = () => {
    if (!data) return
    const success = copy(`${data.name}「复制链接到浏览器打开」` + '\n' + `https://dapanso.com/s/${encodeStr(data.id + '')}`)
    // const success = copy(`${data.name}「复制链接到浏览器打开」` + '\n' + `https://dapanso.com/s/${data.id}`)
    success && notice('分享链接复制成功', 'info')
  }
  const onDelete = async () => {
    if (!data) return
    try {
      // const yes = window.confirm("确定删除该资源");
      // if (yes) {
      //先直接删除
      eventBus.emit('delete', {id: data.id})
      await Api.deleteItem(data.id)
      //   notice('删除成功', 'info')
      // }
    } catch (e) {
      // hideLoading()
      notice(e + '', 'error')
    }
  }
  const onClick = () => {
    if (!data) return
    window.open(data.url, 'about:blank')
  }
  const onCheck = async (status: ItemStatus) => {
    if (!data) return
    try {
      eventBus.emit('check', {id: data.id, status})
      await Api.check({id: data.id, status})
      notice('操作成功', 'success')
    } catch (e) {
      notice(e + '', 'error')
    }
  }
  const onPassed = () => onCheck(ItemStatus.passed)
  const onReject = () => onCheck(ItemStatus.reject)
  const onFailed = () => onCheck(ItemStatus.failed)
  return {
    onShare, onClick, onPassed, onReject, onFailed, onDelete,
  }
}
