import '../styles/Detail.scss'
import {useMediaQuery} from "react-responsive";
import {Header} from "../components/Header";
import React from "react";
import {useParams} from "react-router-dom";
import {getNoticeData} from "../utils/utils";
import {LoadingImg} from "../components/Loading";
import {useMedia} from "../hooks/useMedia";

export const NoticeDetail = () => {
  const {id} = useParams()
  const {isDesktop} = useMedia()
  if (!id) return null
  const data = getNoticeData().find(item => item.id == parseInt(id))
  return (
      <>
        <Header/>
        <div className={'detail'}>
          <div className={'detail-left'}>
            {
              !data ?
                  <div className={'page-loading-wrap'}>
                    <LoadingImg/>
                  </div> :
                  <>
                    <div className={'user-wrap'}>
                      <p className={'username'}>官方公告</p>
                      <span className={'time'}>{data.createdAt.split(' ')[0]}</span>
                    </div>
                    <p
                        className={'title'}
                        style={{fontWeight: 400, lineHeight: isDesktop ? 1.9 : 1.5}}
                        dangerouslySetInnerHTML={{__html: data.content}}
                    />
                  </>
            }
          </div>
          <div className="detail-right"/>
        </div>
      </>
  )
}