import '../styles/Upload.scss'
import {Button} from '../components/Button'
import {DataType} from '../constants'
import {Input} from '../components/Input'
import {useMedia} from '../hooks/useMedia'
import {ChangeEventHandler, useState} from 'react'
import {Api} from '../net/api'
import {notice} from '../utils/notification'
import {hideLoading, showLoading} from '../utils/eventBus'
import {isUrl} from '../utils/utils'
import {ItemModule} from '../net/module/itemModule'

interface Props {
  data: ItemModule;

  onSuccess?(data: ItemModule): void;

  onClose(): void;
}

export const Edit = ({data, onSuccess, onClose}: Props) => {
  const {isDesktop} = useMedia()
  // const location = useLocation()
  // const data = location.state as ItemModule
  const [type, setType] = useState(data.type)
  const [name, setName] = useState(data.name)
  const [url, setUrl] = useState(data.url || '')
  const [description, setDescription] = useState(data.description || '')
  const [coverLink, setCoverLink] = useState(data.coverLink || '')
  // const navigate = useNavigate()
  const upload = async () => {
    if (!name || !url) {
      notice('标题和资源链接不能为空', 'info')
      return
    }
    if (!isUrl(url)) {
      notice('资源链接格式不正确', 'info')
      return
    }
    // if (coverLink && !isUrl(coverLink)) {
    //   notice('封面链接格式不正确', 'info')
    //   return
    // }
    try {
      //直接跳转，在弹提示
      onSuccess?.({...data, name, type, url, description, coverLink})
      onClose()
      // showLoading()
      const changeData = {id: data.id, name, type, url, description, coverLink} as ItemModule
      const {ret} = await Api.edit(changeData)
      // hideLoading()
      notice('资源修改成功', 'success')
    } catch (e) {
      // hideLoading()
      notice(e + '', 'error')
    }
  }

  const onChange: ChangeEventHandler<HTMLSelectElement> = (e) => {
    setType(parseInt(e.target.value))
    e.stopPropagation()
  }
  const onTextareaChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setDescription(e.target.value)
    e.stopPropagation()
  }
  const onLinkChange: ChangeEventHandler<HTMLTextAreaElement> = (e) => {
    setUrl(e.target.value)
    e.stopPropagation()
  }
  return (
    <>
      {/*<Header/>*/}
      <div className={'upload'}>
        <div className={'up-left'}>
          <div className={'select-wrap'}>
            {Object.values(DataType).map((item, index) => {
              const isActive = type === item.value
              return (
                <div
                  key={item.value}
                  className={'type-item ' + (isActive ? 'active' : '')}
                  onClick={() => setType(item.value)}
                  style={isActive ? {backgroundColor: item.color} : undefined}
                >
                  {item.text}
                </div>
              )
            })}
            {/*<div className="arrow"/>*/}
            {/*<select name="type" value={type} onChange={onChange}>*/}
            {/*  {*/}
            {/*    Object.values(DataType).map((item, index) => {*/}
            {/*      return <option key={item.value} value={item.value}>{item.text}</option>*/}
            {/*    })*/}
            {/*  }*/}
            {/*</select>*/}
          </div>
          <Input type="text" placeholder={'资源标题（必填）'} value={name} onChange={setName} onSubmit={upload}/>
          <textarea rows={5} placeholder={'资源链接 https://（必填）'} value={url} onChange={onLinkChange} onSubmit={upload}/>
          <Input type="text" placeholder={'封面链接 https://'} value={coverLink} onChange={setCoverLink} onSubmit={upload}/>
          <div className={'desc-wrap'}>
            <textarea placeholder={'资源描述'} rows={isDesktop ? 20 : 8} value={description} onChange={onTextareaChange}/>
            {coverLink ? <img src={coverLink} alt="封面格式错误"/> : null}
          </div>
          <div style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Button text={'取消'} onClick={onClose} style={{marginRight: 15}} color={'rgb(218, 101, 94)'}/>
            <Button text={'确定'} onClick={upload} disabled={!name || !url}/>
          </div>
        </div>
        <div className={'up-right'}/>
      </div>
    </>
  )
}
